<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/logo.png" class="img-logo-n" />
      </b-link>
      <div style="position: absolute; right: 0; padding: 30px; z-index: 1">
        <b-link @click="$i18n.locale = 'en'"> EN </b-link>
        |
        <b-link @click="$i18n.locale = 'id'"> ID </b-link>
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <!-- <b-link style=" position: absolute; padding: 18px; margin: 0 136px;top: 50px;">
            <img src="@/assets/images/logo/logo.png" class="img-title"/>
          </b-link> -->

        <b-col sm="8" md="12" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("form.page.login.Welcome") }}
          </b-card-title>
          <b-card-text class="mb-2" v-if="formState != 2">
            {{ $t("form.page.login.Please Sign In") }}
          </b-card-text>
          <b-card-text class="mb-2" v-if="formState == 2">
            {{ $t("form.page.login.Insert Email For OTP") }}
          </b-card-text>

          <!-- form Login (state 1) -->
          <validation-observer ref="loginValidation" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent v-if="formState == 1">
              <!-- email -->
              <b-form-group label="Customer ID" label-for="login-email">
                <validation-provider #default="{ errors }" name="Customer ID" rules="required">
                  <b-form-input id="login-email" v-model="loginForm.customer_id" :state="errors.length > 0 ? false : null" name="login-email" placeholder="Customer ID"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>{{ $t("form.page.login.Forgot Password") }}</small>
                  </b-link>
                </div>
                <validation-provider #default="{ errors }" name="Password" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input id="login-password" v-model="loginForm.password" :state="errors.length > 0 ? false : null" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="············"/>
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--<b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>-->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
                v-if="!isLoading"
              >
                {{ $t("form.page.login.Sign in") }}
              </b-button>
              <!-- Request OTP buttons -->
              <b-button
                type="submit"
                variant="warning"
                block
                @click="formState = 2"
                v-if="!isLoading"
              >
                {{ $t("form.page.login.Request OTP") }}
              </b-button>
              <loading v-if="isLoading"></loading>
            </b-form>
          </validation-observer>

          <!-- form Request OTP (state 2) -->
          <validation-observer ref="requestOTPValidation" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent v-if="formState == 2">
              <!-- email -->
              <b-form-group label="Customer Code" label-for="login-customer-code">
                <validation-provider
                  #default="{ errors }"
                  name="Customer Code"
                  rules="required"
                >
                  <b-form-input
                    id="login-customer-code"
                    v-model="requestOTPForm.customer_code"
                    :state="errors.length > 0 ? false : null"
                    name="login-customer-code"
                    placeholder="Customer Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="warning" block @click="submitRequestOTP" v-if="!isLoading">
                {{ $t("form.page.login.Login With OTP") }}
              </b-button>
              <loading v-if="isLoading"></loading>
              <p class="text-center mt-2" @click="formState = 1" v-if="!isLoading">
                <b-link>
                  <feather-icon icon="ChevronLeftIcon" />
                  {{ $t("form.page.login.Back to login") }}
                </b-link>
              </p>
            </b-form>
          </validation-observer>

          <!-- form Login OTP (state 3) -->
          <validation-observer ref="loginOTPValidation" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent v-if="formState == 3">
              <!-- OTP Code -->
              <b-form-group label="OTP Code" label-for="login-otp">
                <validation-provider #default="{ errors }" name="otp" rules="required">
                  <b-form-input id="login-otp" v-model="loginForm.password" :state="errors.length > 0 ? false : null" name="OTP Code " placeholder="OTP Code"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block @click="validationForm" v-if="!isLoading">Login</b-button>
              <loading v-if="isLoading"></loading>
            </b-form>
          </validation-observer>

          <!-- <b-card-text class="text-center mt-2" v-if="!isLoading">
            <span>{{ $t("form.page.login.New on our platform") }}</span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;{{ $t("form.page.login.Subscribes") }}</span>
            </b-link>
          </b-card-text> -->

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">or</div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button variant="facebook" href="javascript:void(0)">
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button variant="twitter" href="javascript:void(0)">
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button variant="google" href="javascript:void(0)">
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button variant="github" href="javascript:void(0)">
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <div id="chat2"></div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Loading from "@core/components/loading/Loading.vue";

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";
import { isMobile } from "mobile-device-detect";
import QiscusSDK from "qiscus-sdk-core";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Loading,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      formState: 1, // 1 login | 2. request OTP | 3. Login OTP
      loginForm: { customer_id: null, password: null },
      requestOTPForm: { customer_code: null },
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      nonce: "",
      data: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.isLoading = true;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          if (this.formState == 3) {
            this.loginForm.customer_id == null;
          }
          axios
            .post("authentication/login/", this.loginForm)
            .then((response) => {
              if (response.data.status === 2000) {
                localStorage.setItem("customer_category", response.data.data.customer_category);
                localStorage.setItem("token-hydra", response.data.token);
                localStorage.setItem("token", response.data.token_jwt);
                localStorage.setItem("customer_code", response.data.data.customer_id);
                localStorage.setItem("name", response.data.data.name);
                localStorage.setItem("customer_id", response.data.data.hydra_cust_id);
                localStorage.setItem("account_id", response.data.data.hydra_account_id);
                localStorage.setItem("subscriber_ntivi", response.data.data.subscriber_ntivi);
                localStorage.setItem("subscriber_ndrive", response.data.data.subscriber_ndrive);
                // this.handleSubmit();

                this.$router.push({ name: "dashboard" });

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
              } else {
                // this.$router.push({ name: "login" });

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login",
                    icon: "EditIcon",
                    variant: "error",
                    text: response.data.message,
                  },
                });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Login",
                  icon: "EditIcon",
                  variant: "success",
                  text: error.response.messages,
                },
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
    },
    submitRequestOTP() {
      this.isLoading = true;
      this.$refs.requestOTPValidation.validate().then((success) => {
        if (success) {
          axios
            .post("authentication/request-otp/", this.requestOTPForm)
            .then((response) => {
              if (response.data.status === 2000) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login",
                    icon: "EditIcon",
                    variant: "success",
                    text: response.data.message,
                  },
                });
                this.formState = 3;
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Login",
                    icon: "EditIcon",
                    variant: "error",
                    text: response.data.message,
                  },
                });
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Login",
                  icon: "EditIcon",
                  variant: "success",
                  text: error.response.messages,
                },
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      });
    },
    init: function () {
      // hide botpress iframe
      if (document.getElementById("bp-web-widget") !== null) {
        document.getElementById("bp-web-widget").style.display = "none";
      }
      // this.open_chat();
    },
    callingToQiscus: function () {
      const qiscus = new QiscusSDK();
      let nonce;
      //let data;
      const identityToken = "";
      const roomId = "";
      qiscus.init({
        AppId: "zil-gnbflww3n9zcsdwf5",
        mode: "widget",
        options: {
          commentDeliveredCallback(data) {
            //console.log(data);
          },
          loginSuccessCallback(data) {
            //console.log(window.customer_room);
            qiscus
              .sendComment(window.roomId, "coba sekarang", null, "text", null, null)
              .then((res) => {
                //console.log("Send comment from loginSuccess");
                //console.log(res);
              });
          },
        },
      });

      axios
        .post("qiscus/getIdentityToken")
        .then((response) => {
          const initiate_chat = response.data.initiate_chat.data;
          const customer_room = initiate_chat.customer_room;
          const identity_token = initiate_chat.identity_token;
          window.roomId = customer_room.room_id;
          qiscus.verifyIdentityToken(identity_token).then((res) => {
            qiscus.setUserWithIdentityToken(res);
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Login",
              icon: "EditIcon",
              variant: "success",
              text: error.response.messages,
            },
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
