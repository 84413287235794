var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticClass:"img-logo-n",attrs:{"src":require("@/assets/images/logo/logo.png")}})]),_c('div',{staticStyle:{"position":"absolute","right":"0","padding":"30px","z-index":"1"}},[_c('b-link',{on:{"click":function($event){_vm.$i18n.locale = 'en'}}},[_vm._v(" EN ")]),_vm._v(" | "),_c('b-link',{on:{"click":function($event){_vm.$i18n.locale = 'id'}}},[_vm._v(" ID ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"12","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Welcome"))+" ")]),(_vm.formState != 2)?_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Please Sign In"))+" ")]):_vm._e(),(_vm.formState == 2)?_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Insert Email For OTP"))+" ")]):_vm._e(),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.formState == 1)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Customer ID","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"Customer ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Customer ID"},model:{value:(_vm.loginForm.customer_id),callback:function ($$v) {_vm.$set(_vm.loginForm, "customer_id", $$v)},expression:"loginForm.customer_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t("form.page.login.Forgot Password")))])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Sign in"))+" ")]):_vm._e(),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"warning","block":""},on:{"click":function($event){_vm.formState = 2}}},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Request OTP"))+" ")]):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e()],1):_vm._e()]}}])}),_c('validation-observer',{ref:"requestOTPValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.formState == 2)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"Customer Code","label-for":"login-customer-code"}},[_c('validation-provider',{attrs:{"name":"Customer Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-customer-code","state":errors.length > 0 ? false : null,"name":"login-customer-code","placeholder":"Customer Code"},model:{value:(_vm.requestOTPForm.customer_code),callback:function ($$v) {_vm.$set(_vm.requestOTPForm, "customer_code", $$v)},expression:"requestOTPForm.customer_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"warning","block":""},on:{"click":_vm.submitRequestOTP}},[_vm._v(" "+_vm._s(_vm.$t("form.page.login.Login With OTP"))+" ")]):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e(),(!_vm.isLoading)?_c('p',{staticClass:"text-center mt-2",on:{"click":function($event){_vm.formState = 1}}},[_c('b-link',[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("form.page.login.Back to login"))+" ")],1)],1):_vm._e()],1):_vm._e()]}}])}),_c('validation-observer',{ref:"loginOTPValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.formState == 3)?_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":"OTP Code","label-for":"login-otp"}},[_c('validation-provider',{attrs:{"name":"otp","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-otp","state":errors.length > 0 ? false : null,"name":"OTP Code ","placeholder":"OTP Code"},model:{value:(_vm.loginForm.password),callback:function ($$v) {_vm.$set(_vm.loginForm, "password", $$v)},expression:"loginForm.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validationForm}},[_vm._v("Login")]):_vm._e(),(_vm.isLoading)?_c('loading'):_vm._e()],1):_vm._e()]}}])})],1)],1)],1),_c('div',{attrs:{"id":"chat2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }